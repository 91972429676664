import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player/youtube"
import { useIntersection } from "react-use"

import { FadeInUpBox } from "@atoms/FadeInUpBox/FadeInUpBox"
import Image from "@atoms/Image/Image"
import { IntersectionObserver } from "@atoms/IntersectionObserver/IntersectionObserver"
import { MotionBox } from "@atoms/MotionBox/MotionBox"
import Shape from "@atoms/Shape/Shape"
import { StaggerWrap } from "@atoms/StaggerWrap/StaggerWrap"
import "@lang/i18n"

import styles from "./VideoStory.module.scss"

function youtube_parser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

const VideoStory = ({ meta, title, text, cta, poster, mp4, youTubeUrl }) => {
  const { t } = useTranslation()
  const [play, setPlay] = useState(false)
  const [playedOnce, setPlayedOnce] = useState(false)

  // TODO: inView thingy works perfectly, but you should make this use IntersectionObserver
  const [inView, setInView] = useState(false)

  const video = useRef()

  const youTubeID = youTubeUrl ? youtube_parser(youTubeUrl) : null

  const youTubeThumbnail = youTubeID
    ? `https://i3.ytimg.com/vi/${youTubeID}/maxresdefault.jpg`
    : null

  useEffect(() => {
    if (video) {
      if (play) {
        video.current?.play()
      } else {
        video.current?.pause()
      }
    }
  })

  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    threshold: 0,
  })

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > 0
    if (inViewNow) {
      return setInView(inViewNow)
    }
  }, [intersection])

  const handleClick = (event) => {
    setPlay(true)
    setPlayedOnce(true)
  }

  return (
    <IntersectionObserver>
      <section
        ref={intersectionRef}
        className={`${styles.element} ${inView ? styles.visible : null} ${
          play ? styles.elementStatePlay : ""
        } make-full-width-on-mobile base-margin-top-xl base-padding-top base-padding-bottom base-margin-bottom-xl`}
      >
        <StaggerWrap>
          <FadeInUpBox>
            <MotionBox
              animate={play ? { opacity: 0 } : { opacity: 1 }}
              className={styles.content}
            >
              <div className={styles.text}>
                <h2>
                  <span className={`${styles.meta}`}>{meta}</span>
                  {title}
                </h2>
                <div className="d-none d-lg-block">{text}</div>
              </div>
            </MotionBox>
          </FadeInUpBox>

          <div className={`${styles.video} ${play ? styles.statePlay : ""}`}>
            <div className={styles.videoContainer}>
              {youTubeUrl && (
                <div className={styles.youtube}>
                  {poster && (
                    <div
                      className={`${styles.poster} ${
                        playedOnce ? styles.playedOnce : null
                      }`}
                    >
                      <Image
                        image={{ sourceUrl: poster }}
                        fill={true}
                        ariaHidden={true}
                        alt=""
                      />
                    </div>
                  )}
                  {!poster && (
                    <div className={styles.poster}>
                      <Image
                        image={{ sourceUrl: youTubeThumbnail }}
                        fill={true}
                        ariaHidden={true}
                        alt=""
                      />
                    </div>
                  )}
                  <div className={styles.youtubeVideo}>
                    {playedOnce && (
                      <ReactPlayer
                        config={{
                          youtube: {
                            playerVars: { controls: 0, modestbranding: 1 },
                          },
                        }}
                        controls={false}
                        url={youTubeUrl}
                        width="100%"
                        height="100%"
                        playing={play}
                      />
                    )}
                  </div>
                </div>
              )}
              {!youTubeUrl && mp4 && (
                <video // eslint-disable-line jsx-a11y/media-has-caption
                  ref={video}
                  controls={play}
                  preload="metadata"
                  playsInline=""
                  poster={poster}
                  tabIndex="-1"
                  src={mp4}
                ></video>
              )}
            </div>
            <div className={styles.trigger}>
              <Shape type="triangle-bottom-video-story" color="coral" />
              <button onClick={handleClick} aria-label={cta}>
                <span className="d-none d-lg-block" aria-hidden>
                  {cta}
                </span>
                <span></span>
              </button>
            </div>
          </div>

          <button
            className={`${styles.close} ${play ? styles.statePlay : ""}`}
            onClick={() => setPlay(false)}
          >
            {t("Close the video")}
          </button>
        </StaggerWrap>
      </section>
    </IntersectionObserver>
  )
}

export default VideoStory
